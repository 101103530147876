import config from 'config'
import { useMediaSize } from 'modules/browser'

export function getImageUrlWithTransformation(product) {
  if (typeof window === 'undefined') return product.imageMediumURL
  const c = config.modules.cloudinary
  const { activeMs } = useMediaSize('>=', 'LAPTOP_XL')
  const dpr = window.devicePixelRatio
  const noCloudinaryImageFetchMerchants = config.blacklists.noCloudinaryImageFetchMerchants

  // default: build Cloudinary image fetch url
  let imageUrl = `${c.imageFetchUrl}${
    c.imageTransformations.productwidget[activeMs]
  },dpr_${dpr}/${encodeURIComponent(product.imageMediumURL)}`

  // backlisted merchant, use merchant image url, assure (force!) httpS/ SSL
  if (noCloudinaryImageFetchMerchants.indexOf(product.merchantName) !== -1) {
    imageUrl = product.imageMediumURL.replace('http://', 'https://')
  }
  return imageUrl
}
