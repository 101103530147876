import * as React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Link from 'theme/atoms/Link'
import Container from 'theme/atoms/Container'
import config from 'config'

type Props = {
  small?: boolean
  crumbs: {
    label: string
    link?: string
  }[]
}

export default function Breadcrumbs(props: Props) {
  return (
    <Wrapper className="Breadcrumbs" small={props.small}>
      <Helmet htmlAttributes={{ lang : 'de' }}>
        <script id="breadcrumbs" type="application/ld+json">
          {createLd(props)}
        </script>
      </Helmet>
      {props.crumbs.map((crumb) => (
        <Link key={crumb.label} to={crumb.link}>
          {crumb.label}
        </Link>
      ))}
    </Wrapper>
  )
}

function createLd(props: Props) {
  let json = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: props.crumbs.map((item, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      name: item.label,
      item: item.link ? config.siteUrl + item.link : undefined,
    })),
  }
  return JSON.stringify(json)
}

const Wrapper = styled(Container)`
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: Raleway;
  font-size: 11px;
  letter-spacing: 2.4px;
  line-height: 25px;

  > * {
    &:not(:first-child):before {
      content: ' > ';
    }
  }

  > span {
    color: #a7a9ac;
  }

  > a {
    color: #993350;
    text-decoration: none;
  }
`
