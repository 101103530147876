import * as React from 'react'
import styled from 'styled-components'
import useBanner from 'hooks/useBanner'
import { ms } from 'modules/browser/const'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import Link from 'theme/atoms/Link'

export default function Banner () {
  const banner = useBanner()
  const [showModal, setShowModal] = React.useState(false)
  
  if(!banner || banner.dummy) return null
  const hasModal = Boolean(banner.popupCtaLabel && banner.popupCtaUrl && banner.popupTitle)

  return (
    <Wrapper className='Banner' bg={banner.backgroundColor}>
      <div className='top' onClick={() => setShowModal(true)}>
        <div className='title'>{banner.title}</div>
        {banner.subtitle && (
          <>
            <div className='pipe'>|</div>
            <div className='sub-title'>{banner.subtitle}</div>
          </>
        )}
        {hasModal && (
          <>
            <div className='desktop-icon'>
              <AiOutlineInfoCircle/>
            </div>
            <div className='mobile-icon'>
              <AiOutlineInfoCircle/>
            </div>
          </>
        )}
      </div>

      {showModal && (
        <Modal bg={banner.backgroundColor}>
          <div className='overlay' onClick={() => setShowModal(false)}/>
          <div className='content'>
            <div className='close' onClick={()=> setShowModal(false)}>X</div>
            <h3>{banner.popupTitle}</h3>
            <p>{banner.popupDescription}</p>
            <Link to={banner.popupCtaUrl} onClick={() => setShowModal(false)}>
              {banner.popupCtaLabel}
            </Link>
            <div className='hint'>{banner.popupHintText}</div>
          </div>
        </Modal>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  text-align: center;
  background: ${props => props.bg};

  @media (min-width: ${ms.LAPTOP}px) {
    padding: 10px;
  }

  > .top {
    padding: 0 30px;
    position: relative;
    > .title {
      font-size: 20px;
      line-height: 26px;
      color: #323232;
      font-weight: bold;
    }
    > .sub-title {
      font-size: 20px;
      line-height: 26px;
      color: #323232;
      margin-top: 6px;
    }
    > .pipe {
      display: none;
    }
    > .desktop-icon {
      display: none;
    }
    .mobile-icon {
      position: absolute;
      right: -5px;
      top: 50%;
      transform: translateY(-50%);
      > svg {
        font-size: 30px;
        margin-top: 5px;
      }
    }
    @media (min-width: ${ms.LAPTOP}px) {
      > .pipe {
        display: inline;
        padding: 0 5px;
        font-weight: bold;
      }
      > .title {
        display: inline;
        font-size: 16px;
        line-height: 20px;
      }
      > .sub-title {
        display: inline;
        font-size: 16px;
        line-height: 20px;
      }
      > .desktop-icon {
        display: inline;
        cursor: pointer;
        > svg {
          font-size: 20px;
          margin-bottom: -4px;
          margin-left: 10px;
        }
      }
      > .mobile-icon {
        display: none;
      }
    }
  }

`

const Modal = styled.div`

  > .overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    z-index: 99999999999999998;
  }

  > .content {
    position: fixed;
    left: 0;
    top: 50%;
    width: 90%;
    max-height: 90%;
    overflow: auto;
    transform: translateY(-50%);
    margin-left: 5%;
    background: ${props => props.bg};
    z-index: 99999999999999999;
    padding: 25px;
    text-align: left;

    @media (min-width: ${ms.LAPTOP}px) {
      width: ${ms.LAPTOP * 0.9}px;
      transform: none;
      margin-top: -150px;
      margin-left: calc((100% - ${ms.LAPTOP * 0.9}px) / 2);
    }

    > h3 {
      font-family: 'Cormorant Garamond';
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 0px;
      margin-bottom: 35px;
    }
    > p {
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0px;
      margin-bottom: 35px;
    }
    > span, > a {
      display: block;
      border: 1px solid #232323;
      padding: 15px;
      color: black;
      text-transform: uppercase;
      font-family: Raleway;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      margin-bottom: 20px;
      text-align: center;
    }
    .hint {
      font-family: 'Open Sans';
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0px;
      margin-bottom: 30px;
    }

    > .close {
      position: absolute;
      right: 0px;
      top: 0px;
      padding: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  }
`