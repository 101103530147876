import * as React from 'react'

export type Banner = {
  title:string
  objectID:string
  popupCtaLabel:string
  popupCtaUrl:string
  popupDescription:string
  popupHintText:string
  popupTitle:string
  subtitle:string
  backgroundColor: string
  dummy?:boolean
}

const Context = React.createContext<Banner|null>(null)

export default function useBanner() {
  return React.useContext(Context)
}

export function BannerProvider (props:{banner:Banner|null, children:any}) {
  return (
    <Context.Provider value={props.banner}>
      {props.children}
    </Context.Provider>
  )
} 