import * as React from 'react'
import styled from 'styled-components'
import toPriceString from 'utils/toPriceString'
import { FaShoppingCart } from 'react-icons/fa'
import Button from 'theme/molecules/Button'
import * as utils from './utils'
import * as actions from './actions'
import { useProductModalId } from 'modules/ui'
import useProduct from './hooks/useProduct'
import useSizeOptions from './hooks/useSizeOptions'

export default function ProductModal() {
  const { objectId, closeProductModal } = useProductModalId()
  const [isFetching, product] = useProduct(objectId || '')
  const sizes = useSizeOptions(product ? product.wunderSizes : null)

  if (!objectId) return null
  if (!isFetching && !product) return null
  if (!product) return null

  const handleClickout = (e) => {
    e.stopPropagation()
    actions.clickout(product)
  }

  return (
    <Wrapper
      className="ProductModal"
      onClick={closeProductModal}
      data-cy-ctx="core/ProductModal"
      data-cy-handle="close-clickout"
    >
      <div className="content" onClick={(e) => e.stopPropagation()} data-cy-handle="content">
        <div className="close-icon" onClick={closeProductModal} data-cy-handle="close-btn" />
        <h2 className="title">{product.productName}</h2>

        <div className="image-wrapper">
          {product.isOnSale && (
            <div className="sale-ribbon" data-cy-state="sale-product-image">
              -{(product.salePercentage * 100).toFixed()}%
            </div>
          )}
          <img alt={product.productName} src={utils.getImageUrlWithTransformation(product)} />
        </div>

        <h5 className="merchant">{product.merchantName}</h5>

        <div className="sizes">
          <h6>Größen</h6>
          <ul>
            {sizes.map((size) => (
              <li key={size}>{size}</li>
            ))}
          </ul>
        </div>

        <div className="property-list">
          <div className="property">
            <div className="label">Marke</div>
            <div className="value">{product.productManufacturerBrand}</div>
          </div>
          <div className="property">
            <div className="label">Farbe</div>
            <div className="value">{product.productColor}</div>
          </div>
          <div className="property">
            <div className="label">Lieferzeit</div>
            <div className="value">{product.deliveryTime}</div>
          </div>
          <div className="property">
            <div className="label">Versandkosten</div>
            <div className="value">{product.shippingInfo}</div>
          </div>
        </div>

        <div className="price-info">
          <div className="current-price">{toPriceString(product.productPrice)}</div>
          {product.isOnSale && product.productPriceOld && (
            <div className="strice-price" data-cy-state="sale-product-text">
              {' '}
              {toPriceString(product.productPriceOld)}
            </div>
          )}
        </div>

        <Button
          filled
          className="clickout"
          onClick={handleClickout}
          data-cy-handle="to-merchant-btn"
        >
          <FaShoppingCart /> zum Shop
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999999999999;

  display: flex;
  align-items: center;
  justify-content: center;

  > .content {
    display: grid;
    width: 100%;
    height: 100%;
    background: white;
    overflow: auto;

    position: fixed;
    z-index: 99999999999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    grid:
      ' CloseIcon CloseIcon       CloseIcon      CloseIcon ' 50px
      '     .     Title           Title              .     ' auto
      '     .     MerchantName    ImageWrapper       .     ' 15px
      '     .     Sizes           ImageWrapper       .     ' auto
      '     .     Sizes           ImageWrapper       .     ' 1fr
      '     .     PropertyList    PropertyList       .     ' auto
      '     .     PriceInfo       PriceInfo          .     ' 50px
      '     .     Clickout        Clickout           .     ' 80px
      / 15px 4fr 5fr 15px;
    grid-gap: 5px;

    @media screen and (orientation: landscape) {
      grid:
        ' CloseIcon CloseIcon       CloseIcon      CloseIcon ' 50px
        '     .     Title           Title              .     ' auto
        '     .     MerchantName    ImageWrapper       .     ' auto
        '     .     Sizes           ImageWrapper       .     ' auto
        '     .     PropertyList    ImageWrapper       .     ' 1fr
        '     .     PropertyList    PriceInfo          .     ' 20px
        '     .     PropertyList    Clickout           .     ' 50px
        / 10px 3fr 2fr 10px;
      grid-gap: 10px;
    }

    @media screen and (min-width: 1000px) {
      width: 900px;
      height: 600px;
      transform: translateX(-50%);
      left: 50%;

      margin-top: 30px;

      grid:
        '     .          .                .        CloseIcon ' 50px
        '     .     ImageWrapper    Title              .     '
        '     .     ImageWrapper    MerchantName       .     '
        '     .     ImageWrapper    Sizes              .     '
        '     .     ImageWrapper    PropertyList       .     '
        '     .     ImageWrapper    PriceInfo          .     ' 50px
        '     .     ImageWrapper    Clickout           .     ' 1fr
        / 20px 1fr 1fr 20px;
      grid-gap: 10px;
    }

    > .close-icon {
      grid-area: CloseIcon;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
      cursor: pointer;
      background: rgb(153, 52, 82);
      color: white;

      @media (min-width: 1000px) {
        background: none;
        color: rgb(167, 169, 172);
      }

      &:after {
        content: 'x';
        font-weight: bold;
        font-size: 24px;
      }
    }

    > .title {
      grid-area: Title;
      font-size: 16px;
    }

    > .image-wrapper {
      grid-area: ImageWrapper;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 50vw;
      @media screen and (orientation: landscape) {
        width: 100%;
      }
      @media screen and (min-width: 1000px) {
        width: 100%;
      }

      > img {
        margin: 0 auto;
        display: block;

        max-height: 100%;
        max-width: 100%;
      }

      > .sale-ribbon {
        position: absolute;
        left: 0;
        top: 0;
        background: #a7a9ac;
        border-radius: 0 0 50px;
        width: 50px;
        height: 50px;
        color: white;
        padding: 12px 0 0 4px;
      }
    }

    > .merchant {
      grid-area: MerchantName;
      color: rgb(167, 169, 172);
      margin: 0;
    }

    > .sizes {
      grid-area: Sizes;
      > h6 {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 16px;
      }
      > ul {
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        > li {
          background: rgb(204, 204, 204);
          margin: 3px;
          width: 50px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    > .property-list {
      grid-area: PropertyList;
      > .property {
        padding: 3px;
        display: flex;
        align-items: flex-start;

        &:nth-child(2n) {
          background: rgb(238, 236, 237);
        }

        > .label {
          display: inline-block;
          width: 50%;
          font-weight: bold;
        }

        .value {
          display: inline-block;
          width: 50%;
        }
      }
    }

    > .price-info {
      grid-area: PriceInfo;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > .current-price {
        font-weight: 700;
        font-size: 20px;
      }

      > .strice-price {
        font-weight: 700;
        text-decoration: line-through;
        color: rgb(167, 169, 172);
        font-size: 20px;
        margin-left: 20px;
      }
    }

    > .clickout {
      grid-area: Clickout;
      height: 35px;
    }
  }
`
