import * as React from 'react'
import Redux from './Redux'
import ProductModal from 'theme/_core/ProductModal'
import Breadcrumbs from 'theme/atoms/Breadcrumbs'
import Banner from 'theme/_core/Banner'
import styled from 'styled-components'
import 'scroll-behavior-polyfill'

// if (!("scrollBehavior" in document.documentElement.style)) {
//   import("scroll-behavior-polyfill");
// }

type Props = {
  children: any
  partialStateUpdates?: Record<string, unknown>[] | false | null
  small?: boolean
  breadcrumbs?: {
    label: string
    link?: string
  }[]
}

export default function Layout({ children, partialStateUpdates, breadcrumbs, small }: Props) {
  return (
    <Wrapper small={small}>
      <Banner />
      <Redux partialStateUpdates={partialStateUpdates}>
        {breadcrumbs && (
          // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
          <div style={{ marginTop: 10 }}>
            <Breadcrumbs small={small} crumbs={breadcrumbs} />
          </div>
        )}
        {children}
        <div style={{ marginBottom: 40 }} />
        <ProductModal />
      </Redux>
    </Wrapper>
  )
}

const Wrapper = styled.div``
