import * as React from 'react'
import config from 'config'
import { createSearchHelper } from 'utils/searchClient'

export default function useProduct(objectID: string) {
  const [[isFetching, product], setProduct] = React.useState<any>([true, null])

  React.useEffect(() => {
    if (!objectID) {
      if (product || !isFetching) setProduct([true, null])
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const helper = createSearchHelper(config.indexes.products, {
        disjunctiveFacets: ['objectID'],
      })
      helper.addDisjunctiveFacet('objectID', objectID)
      const result = await helper.search()
      const product = result.hits[0] || null
      setProduct([false, product])
    })()
    // eslint-disable-next-line
  }, [objectID])

  return [isFetching, product]
}
